
	frappe.templates['qmr'] = `<div class="qmr">
	<div class="qmr-group">
    </div>
	<div class="stock-levels qmr-item">
		<div class="result">
		</div>
		<div class="more hidden" style="padding: 15px;">
			<a class="btn btn-default btn-xs btn-more">More</a>
		</div>
	</div>
</div>`;
